import { Container, Row, Col } from 'reactstrap';

const Credits = () => {
  return (
    <div className="page">
      <Container fluid>
        <Row>
          <Col>
            <h2 align="center">Credits</h2>
            <br />
            <br />
          </Col>
        </Row>

        {/* <hr /> */}
        <Row>
          <Col>
            <h4>Technical Stack</h4>

            <dl>
              <dt>Core:</dt>
              <dd>
                <a
                  href="https://react.dev/"
                  target="_blank"
                  rel="noreferrer nofollow"
                >
                  ReactJS
                </a>
              </dd>
              <dd>
                <a
                  href="https://mdxeditor.dev/"
                  target="_blank"
                  rel="noreferrer nofollow"
                >
                  MDX Editor
                </a>
              </dd>
              <dd>
                <a
                  href="https://reactrouter.com/"
                  target="_blank"
                  rel="noreferrer nofollow"
                >
                  React Router Dom
                </a>
              </dd>

              <dt>State:</dt>
              <dd>
                <a
                  href="https://redux.js.org/"
                  target="_blank"
                  rel="noreferrer nofollow"
                >
                  Redux
                </a>
              </dd>
              <dd>
                <a
                  href="https://redux-toolkit.js.org/"
                  target="_blank"
                  rel="noreferrer nofollow"
                >
                  Redux Toolkit
                </a>
              </dd>

              <dt>Design:</dt>
              <dd>
                <a
                  href="https://getbootstrap.com/"
                  target="_blank"
                  rel="noreferrer nofollow"
                >
                  Bootstrap
                </a>
              </dd>
              <dd>
                <a
                  href="https://reactstrap.github.io/"
                  target="_blank"
                  rel="noreferrer nofollow"
                >
                  Reactstrap
                </a>
              </dd>
            </dl>

            <h4>Contact us</h4>

            <p>
              If you have any questions please contact our support via email:{' '}
              <a
                href="mailto:support@dit.software?subject=Notes - Question&body=I have a question about ..."
                target="_blank"
                rel="noreferrer"
              >
                support@dit.software
              </a>
              .
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Credits;
