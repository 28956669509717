const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 bytes';

  const type = 'KB'; // KB or KiB
  const config = {
    KB: {
      k: 1000,
      sizes: ['bytes', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb']
    },
    KiB: {
      k: 1024,
      sizes: ['bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
    }
  };
  const k = config[type].k;
  const sizes = config[type].sizes;
  const dm = decimals < 0 ? 0 : decimals;
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export default formatBytes;
