import { useState, useEffect, useRef } from 'react';
import {
  MDXEditor,
  headingsPlugin,
  listsPlugin,
  linkPlugin,
  quotePlugin,
  toolbarPlugin,
  directivesPlugin,
  codeBlockPlugin,
  // BlockTypeSelect,
  BoldItalicUnderlineToggles,
  ListsToggle,
  AdmonitionDirectiveDescriptor
} from '@mdxeditor/editor';
import { useSelector, useDispatch } from 'react-redux';
import { updateContent, selectContent } from '../../state/contentSlice';
import storage from '../../services/storage';
import './Editor.scss';

const Editor = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const ref = useRef(null);
  const content = useSelector(selectContent);
  const dispatch = useDispatch();

  const onChange = e => {
    dispatch(updateContent(e));
  };

  useEffect(() => {
    if (!isLoaded) {
      setIsLoaded(true);
      ref.current?.setMarkdown(content);
      ref.current?.focus();
    }
  }, [content, isLoaded]);

  useEffect(() => {
    storage
      .get('content')
      .then(contentInStorage => {
        if (contentInStorage) {
          const contentParsed = JSON.parse(contentInStorage);
          dispatch(updateContent(contentParsed.content));
        }
      })
      .catch(err => console.error('content', err));
  }, [dispatch]);

  return (
    <div className="editor-container">
      <MDXEditor
        ref={ref}
        markdown={''}
        onChange={onChange}
        contentEditableClassName="editor-editable"
        plugins={[
          directivesPlugin({
            directiveDescriptors: [AdmonitionDirectiveDescriptor]
          }),
          codeBlockPlugin({ defaultCodeBlockLanguage: 'js' }),
          headingsPlugin(),
          linkPlugin(),
          listsPlugin(),
          quotePlugin(),
          toolbarPlugin({
            toolbarContents: () => (
              <>
                <BoldItalicUnderlineToggles />
                <ListsToggle />
                {/* <BlockTypeSelect /> */}
                {/* <CodeToggle /> */}
                {/* <InsertAdmonition /> */}
                {/* <InsertTable /> */}
                {/* <InsertCodeBlock /> */}
              </>
            )
          })
        ]}
      />
    </div>
  );
};

export default Editor;
