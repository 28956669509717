import { Container, Row, Col, Button, Alert } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { selectTheme, updateTheme } from '../../state/themeSlice';
import { updateConfig } from '../../state/configSlice';
import { updateContent, selectContent } from '../../state/contentSlice';
import THEMES from '../../const/themes';
import logo from '../../assets/images/logo.svg';
import format from '../../services/format';
import './Settings.scss';

const Settings = () => {
  const theme = useSelector(selectTheme);
  const content = useSelector(selectContent);
  const dispatch = useDispatch();

  const setNewTheme = themeName => {
    dispatch(updateTheme(themeName));
    dispatch(updateConfig({ theme: themeName }));
  };

  const removeAll = () => {
    if (window.confirm('Are you sure to delete all notes?')) {
      dispatch(updateContent(''));
    }
  };

  return (
    <div className="page">
      <Container>
        <Row>
          <Col>
            <h2 align="center">Settings</h2>
            <br />
            <br />
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>
              Theme: <strong>{theme}</strong>
            </h4>

            <br />

            {Object.keys(THEMES).map(themeName => (
              <div key={`theme-sample-container-${themeName}`}>
                <div
                  className="p-3 my-2 rounded"
                  style={{
                    backgroundColor: THEMES[themeName].bgBody,
                    color: THEMES[themeName].colorBody,
                    border: `1px solid ${THEMES[themeName].dark}`
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                  >
                    <strong>{themeName}</strong>

                    <Button
                      onClick={() => setNewTheme(themeName)}
                      style={{
                        backgroundColor: THEMES[themeName].bgBody,
                        color: THEMES[themeName].colorBody
                      }}
                    >
                      Select
                    </Button>
                  </div>

                  <br />

                  <div className="theme-sample-header">
                    <img
                      className="logo"
                      src={logo}
                      alt="Notes - Make Your Tasks Done!"
                    />
                  </div>

                  <div className="theme-sample-content">
                    Project A:
                    <br />
                    On track, expected completion by Nov.
                    <br />
                    <br />
                    Project B:
                    <br />
                    Delayed due to supply issues.
                    <br />
                    <br />
                    Project C:
                    <br />
                    Ahead of schedule, beta launch next month.
                    <br />
                    <br />
                    Action Items:
                    <br />
                    [A] Find alternative suppliers (Bob)
                    <br />
                    [A] Revise budget (Diana)
                    <br />
                    [B] Prepare employee survey (Charlie)
                    <br />
                    [C] Redefine scope (Raj)
                  </div>
                </div>
                <br />
              </div>
            ))}
            <br />
            <br />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <Alert color="danger">
              <h4>DANGER!</h4>
              <h5>Remove all {format.bytes(content.length)}.</h5>

              <Button color="danger" onClick={removeAll}>
                <span className="icon-clear"></span> Delete all
              </Button>
            </Alert>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Settings;
